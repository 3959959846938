'use strict';

require('regenerator-runtime/runtime');

const main = require('integrations/main');

main.baseFiles.menu = require('./components/menu');
main.baseFiles.yotpo = require('./components/yotpo');
main.baseFiles.consentTracking = require('./components/consentTracking');
main.baseFiles.warranty = require('./components/warranty');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.base = require('./product/base');
main.baseFiles.timer = require('./components/timer');
main.baseFiles.search = require('./search/search');

module.exports = main;
