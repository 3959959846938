"use strict";
$(document).ready(function() {
    $("body").on("click", "a.product-tile-image-link", function (e) {
        if ($('.permalink').length) {
            // Stop propagation, prevent link from redirecting
            e.stopPropagation();
            e.preventDefault();

            // Retrieve the pid from the product tile, add it to our current URL
            var pid = $(this).parents(".product-tile").data("pid");
            var url = new URL($(".permalink").val());
            url.searchParams.set("clickedpid", pid);
            history.pushState(null, document.title, url.toString());

            // Follow the link 
            window.location = $(this).attr('href');
            return false;    
        }
   });

    // Check for 'clickedpid' in the URL, scroll to it after .5s if it exists
    var urlParams = new URLSearchParams(window.location.search);
    var clickedpid = urlParams.get("clickedpid");
    if (clickedpid) {
        setTimeout(function () {
            $("[data-pid=" + clickedpid + "]")[0].scrollIntoView();
        }, 500);
    }
});

var searchCore = require('core/components/search').init;

// Modified to add classes for availability messages
searchCore.showMore = function () {
    // Show more products
    $(".container").on("click", ".show-more button", function (e) {
        e.stopPropagation();
        e.preventDefault();
        var showMoreUrl = $(this).data("url");
        var $showMoreButton = $(e.target);
        $.spinner().start();
        $(this).trigger("search:showMore", e);

        $.ajax({
            url: showMoreUrl,
            data: { selectedUrl: showMoreUrl },
            method: "GET",
            success: function (response) {
                var promoTileCount = $showMoreButton
                    .closest(".product-grid")
                    .find('[class*="experience-promoTile"]').length;
                $(".grid-footer").replaceWith(response);
                $("body").trigger("search:showMore--success");
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            },
        });
    });
};

searchCore.updateHistory = function () {
    $("body").on("search:showMore--success", function () {
        history.replaceState(null, document.title, $(".permalink").val());
    });
};

module.exports = searchCore;
