const formValidation = require('base/components/formValidation');
const {handleCountrySelect} = require('./helpers').methods;

function init() {
    const countrySelects = $('select[data-state-selects]');

    countrySelects.each(function () {
        handleCountrySelect($(this), 'warranty');
    });
}

function submitForm() {
    $('form.warranty-form').submit(function (e) {
        e.preventDefault();

        const $form = $(this);
        const $formAlert = $form.siblings('.alert');

        $formAlert.addClass('d-none');
        $formAlert.removeClass('alert-danger');
        $formAlert.removeClass('alert-success');

        $form.spinner().start();

        $.ajax({
            url: $form.attr('action'),
            type: 'post',
            dataType: 'json',
            data: $form.serialize()
        })
            .then((data) => {
                if (!data.success) {
                    $formAlert.find('p').html(data.message);
                    $formAlert.addClass('alert-danger').removeClass('d-none');

                    formValidation($form, data);
                } else {
                    $formAlert.find('p').html(data.message);
                    $formAlert.addClass('alert-success').removeClass('d-none');
                    $form.find('.card, .form-actions').addClass('d-none');
                    $form.find('.reload-action').removeClass('d-none');
                }

                $form.spinner().stop();
            })
            .catch((err) => {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    $form.spinner().stop();
                }
            });
    });
}

module.exports = {
    init,
    submitForm
};
