/**
 *
 * @param {Object} $select A jQuery Select object that corresponds to a country select
 * @param {string} context
 *
 * stateSelectObject should look like =
 * object = {
 *      'US': '[selector for the state select]',
 *      'CA': '[selector for the state select]'
 * };
 */
function handleCountrySelect ($select, context) {
    // fetch the data
    const stateSelectData = $select.data('state-selects');

    if (stateSelectData) {
        const stateSelectObject = (typeof stateSelectData === 'object') ? stateSelectData : JSON.parse(stateSelectData);

        $select.on('change', function () {
            const selectValue = $(this).val();

            if (typeof stateSelectObject[selectValue] === 'string') {
                const stateSelect = $(stateSelectObject[selectValue]);

                // hide all the ones but selected one
                Object.keys(stateSelectObject).forEach(function (key) {
                    if (key !== selectValue) {
                        const otherSelect = $(stateSelectObject[key]);
                        const $parent = otherSelect.parent();

                        if (!$parent.hasClass('d-none')) {
                            $parent.addClass('d-none');

                            // move it outside the form
                            $parent.detach().appendTo('.hidden-formfields');
                        }
                    }

                });

                const $parent = stateSelect.parent();

                if ($parent.hasClass('d-none')) {
                    const selector = getStateTarget({
                        context,
                        stateSelect
                    });

                    $parent.removeClass('d-none');
                    $parent.detach().appendTo(selector);
                }
            } else {
                // this means a country code was selected that doesn't have a corresponding state/province select, so hide all of them
                // with exception to the first value - so confirm that the selectValue is not blank / empty
                Object.keys(stateSelectObject).forEach(function (key) {
                    const otherSelect = $(stateSelectObject[key]);
                    const $parent = otherSelect.parent();

                    if (!$parent.hasClass('d-none')) {
                        $parent.addClass('d-none');

                        // move it outside the form
                        $parent.detach().appendTo('.hidden-formfields');
                    }
                });
            }
        });

        // look through the state select data for the country Select and move the hidden ones
        Object.keys(stateSelectObject).forEach(function (key) {
            const stateSelect = $(stateSelectObject[key]);
            const $parent = stateSelect.parent();

            if ($parent.hasClass('d-none')) {
                $parent.detach().appendTo('.hidden-formfields');
            }
        });
    }
}

/**
 * Find the target selector provided a context
 *
 * @param data
 * @returns {string}
 */
function getStateTarget(data) {
    switch (data.context) {
        case 'warranty':
            let targetSelector = '.dealer-block .states';

            if (data.stateSelect.hasClass('ownerState')) {
                targetSelector = '.owner-address-block .states';
            }

            return targetSelector;
        default:
            return '.states';

    }
}

module.exports = {
    methods: {
        handleCountrySelect
    }
}
