'use strict';

function pad2(n) {
    if (n<=9) { n = ("0"+n); }
    return n;
}

function startTimer() {
    var reservationContainer = document.querySelector('.reservation-copy');
    var countdownTimerElement = document.querySelector('.countdown-timer');

    if( reservationContainer && countdownTimerElement) {
        function setCartExpiration () {   
            var now = new Date().getTime();
            var distance = atcTime - now;
            var hours = pad2(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            var minutes = pad2(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
            var seconds = pad2(Math.floor((distance % (1000 * 60)) / 1000));
        
            var countdownDisplay = hours + ':' + minutes + ":" + seconds.toString();
        
            if(distance <= 0) {
                clearInterval(interval);
                countdownTimerElement.innerHTML = '00:00:00';
                $('#timeout-modal').modal('show');
                var action = document.querySelector('.page').dataset.action;
                var clearBasketURL = document.querySelector('.reservation-copy').dataset.clearbasketurl;
                $.ajax({url: clearBasketURL});
                $('#timeout-modal').on('hidden.bs.modal', function (e) {
                    if (action == 'Cart-Show' || action == 'Checkout-Begin') {
                        var redirectURL = document.querySelector('.reservation-copy').dataset.redirecturl;
                        window.location.replace(redirectURL);
                    } else {
                        $('.minicart').trigger('count:update', {quantityTotal:0, minicartCountOfItems:'Cart 0 Items'});
                        $('.reservation-banner').remove();
                    }
                });
            } else {
                countdownTimerElement.innerHTML = countdownDisplay;
            }
        };

        var atcTime = document.querySelector('.reservation-copy').dataset.expiration;
        var interval = null;

        interval = setInterval(setCartExpiration, 1000);
        setCartExpiration();
    };
}

startTimer();

$('body').on('product:afterAddToCart', function (event, data) {
    if ($('.reservation-copy').length == 0) {
        setTimeout(function() {
            $('.reservation-banner').hide().append(data.cartExpirationHTML).fadeIn(1000);
            startTimer();
        }, 5000);
    }
});

$('body').on('cart:update', function (event, data) {
    if (!data.hasReservation) {
        $('.reservation-banner').remove();
    }
});