'use strict';

var tinySlider = require('tiny-slider/src/tiny-slider');
var zoom = require('jquery-zoom');
var imagesLoaded = require('imagesloaded');
require('jquery.scrollintoview');

var SiteConstants = require('constants/SiteConstants');
var mediumBreakpoint = SiteConstants.BreakpointSizes.md;
var largeBreakpoint = SiteConstants.BreakpointSizes.lg;

var abSlider = {};

// Check custom attributes on slider element to get settings, falling back to defaults if not populated
abSlider.getSliderAttributes = function($slider) {
    var attributes = new Object();

    // Autoplay (defaults to false)
    attributes.autoplayDelay = parseInt($slider.attr('data-slider-autoplay'));
    attributes.autoplay = isNaN(attributes.autoplayDelay) ? false : true;
    attributes.autoplayTimeout = attributes.autoplay ? attributes.autoplayDelay : 5000;

    // Loop (defaults to true)
    attributes.loop = $slider.attr('data-slider-loop') === 'false' ? false : true;

    // Number of items to show (defaults to 1 at all breakpoints)
    attributes.itemsNumber = parseInt($slider.attr('data-slider-items'));
    attributes.items = isNaN(attributes.itemsNumber) ? 1 : attributes.itemsNumber;
    attributes.itemsNumberMedium = parseInt($slider.attr('data-slider-items-md'));
    attributes.itemsMedium = isNaN(attributes.itemsNumberMedium) ? 1 : attributes.itemsNumberMedium;
    attributes.itemsNumberLarge = parseInt($slider.attr('data-slider-items-lg'));
    attributes.itemsLarge = isNaN(attributes.itemsNumberLarge) ? 1 : attributes.itemsNumberLarge;

    // Gutter space between items (defaults to 0px)
    attributes.gutter = parseInt($slider.attr('data-slider-gutter')) || 0;
    attributes.gutterMedium = parseInt($slider.attr('data-slider-gutter-md')) || 0;
    attributes.gutterLarge = parseInt($slider.attr('data-slider-gutter-lg')) || 0;

    // Slide animation speed (defaults to 300ms)
    attributes.speed = parseInt($slider.attr('data-slider-speed')) || 300;

    // Slide animation (defaults to standard Carousel behavior)
    attributes.mode = $slider.attr('data-slider-mode') || 'carousel';
    attributes.animateIn = $slider.attr('data-slider-animatein') || 'no-animation';
    attributes.animateOut = $slider.attr('data-slider-animateout') || 'no-animation';

    // Thumbnail settings (defaults to false)
    // If a selector is set for [data-slider-thumbnail container], the rest of the settings populate automatically
    var $thumbnailSlider = $($slider.attr('data-slider-thumbnail-container'));
    attributes.thumbnailContainer = $slider.attr('data-slider-thumbnail-container') || false;
    attributes.navContainer = $thumbnailSlider.length ? $thumbnailSlider.find('.slider')[0] : false;
    attributes.navAsThumbnails = attributes.navContainer === false ? false : true;
    attributes.controlsContainer = $thumbnailSlider.length ? $thumbnailSlider.find('.slider-thumbnail-controls')[0] : false;

    return attributes;
}

// Build parameter object to create a slider
abSlider.getSliderParams = function($slider, slideItems) {
    var attributes = abSlider.getSliderAttributes($slider);
    var params = {
        container: $slider.find('.slider')[0],
        loop: attributes.loop,
        items: attributes.items,
        autoplay: attributes.autoplay,
        autoplayTimeout: attributes.autoplayTimeout,
        autoplayHoverPause: true,
        mode: attributes.mode,
        gutter: attributes.gutter,
        speed: attributes.speed,
        navContainer: attributes.navContainer,
        navAsThumbnails: attributes.navAsThumbnails,
        controlsContainer: attributes.controlsContainer,
        preventScrollOnTouch: 'auto', // prevents mobile errors in chrome
        responsive: {
            [mediumBreakpoint]: {
                items: attributes.itemsMedium,
                gutter: attributes.gutterMedium,
            },
            [largeBreakpoint]: {
                items: attributes.itemsLarge,
                gutter: attributes.gutterLarge,
            }
        }
    }

    if (slideItems) {
        params.slideItems = slideItems;
    }

    if (params.mode === 'gallery') {
        params.animateIn = attributes.animateIn;
        params.animateOut = attributes.animateOut;
    }

    return params;
}

// Uses scrollintoview jQuery plugin (needed for IE11 support) to scroll the active thumbnail into view
// Note: Thumbnail container needs to be scrollable (overflow: auto) for this to work
abSlider.slideIntoView = function(slider) {
    if (slider.navContainer !== false && $(slider.navContainer).hasClass('slider')) {
        var $activeThumbnail = $(slider.navContainer).find('.tns-nav-active');
        $activeThumbnail.scrollintoview();
    }
}

// Build out slide html for replacing images on variant selection
abSlider.getUpdatedSlideItems = function(images, assets, isThumbnail) {
    var slideItems = [];

    images['large'].forEach(image => {
        var $slideElement = $('<div></div>').attr('class', 'slide');
        var $imageElement = $('<img/>');

        if (!isThumbnail) {
            $slideElement.attr('data-zoom-image', images['hi-res'].length ? images['hi-res'][image.index].url : images['large'][image.index].url);
        }

        $imageElement.attr({
            'src': image.url,
            'alt': image.alt + ' image number ' + (parseInt(image.index) + 1).toFixed(0),
            'class': 'd-block img-fluid',
            'itemprop': 'image'
        });

        $slideElement.append($imageElement);
        slideItems.push($slideElement[0]);
    });

    abSlider.getPDPGalleryAssetSlides(slideItems, assets, isThumbnail);

    return slideItems;
}

// Fetch any additional slides needed from PDP Gallery Asset IDs attribute
abSlider.getPDPGalleryAssetSlides = function(slideItems, assets, isThumbnail) {
    let slideCount = slideItems.length;
    let hasVideo = false;

    assets.forEach((asset, index) => {
        var $slideElement = $('<div class="pdp-gallery-asset"></div>');
        var $imageElement = $('<img/>');
        var $videoButton = $('<span class="video-thumbnail-button"></span>');

        if (asset.isVideo) {
            hasVideo = true;
        }

        if (!isThumbnail) {
            $slideElement.attr('class', 'slide no-zoom');
            // ajax call to get html for asset
            $.ajax({
                url: asset.assetRenderUrl,
                method: 'GET',
                success: data => {
                    var $sliderContainer = $slideElement.closest('.slider-container');
                    $slideElement.html(data);

                    // after all assets are added, reinit any necessary javascript
                    if (index + 1 >= assets.length) {
                        $slideElement.trigger('tooltip:init'); // reinit tooltips in case of hotspot asset

                        if (hasVideo) {
                            $('body').trigger('ajax:load.ajaxEvents', [$sliderContainer]); // reinit videos in video/index.js
                            $('body').trigger('slider:videosLoaded'); // trigger custom event set up in bindSliderUpdateEvent
                        }
                    }
                },
                error: err => {
                    console.error('There was an issue retrieving this asset: ', err);
                }
            });
        } else {
            $slideElement.attr('class', 'slide video-container');
            $imageElement.attr({
                'src': asset.thumbnail,
                'alt': 'image number ' + (slideCount + 1).toFixed(0),
                'class': 'd-block img-fluid',
                'itemprop': 'image'
            });
        }

        slideCount++;
        $slideElement.append($imageElement);

        if (asset.isVideo) {
            $slideElement.append($videoButton);
        }

        slideItems.push($slideElement[0]);
    });
}

// Listen for the slider:update event and reinitialize slider with new slides
abSlider.bindSliderUpdateEvent = function($sliderContainer, slider) {
    $sliderContainer.off('slider:update').on('slider:update', '.slider', (event, data) => {
        var $updatedSliderContainer = $(event.target).closest('.slider-container');
        var isThumbnail = $updatedSliderContainer.hasClass('slider-thumbnails');

        // MARTIN MOD : pass zoom flag through to getUpdatedSlideItems which is overridden in client core
        var updatedSlideItems = data !== undefined ? abSlider.getUpdatedSlideItems(data.images, data.assets, isThumbnail, data.zoom) : null;
        var updatedParams = abSlider.getSliderParams($updatedSliderContainer, updatedSlideItems);

        // Update carousel classes to handle number of images changing
        if (isThumbnail) {
            var $thumbnailContainer = $updatedSliderContainer.closest('.primary-images-thumbnails');
            var $mainImageContainer = $thumbnailContainer.prev('.primary-images-main');

            if (data.images.small && data.images.small.length > 1) {
                $thumbnailContainer.removeClass('d-none');
                $mainImageContainer.addClass('product-has-thumbnails col-lg-9');
            } else {
                $thumbnailContainer.addClass('d-none');
                $mainImageContainer.removeClass('product-has-thumbnails col-lg-9');
            }
        }

        // Remove and re-add slider markup if there are new slides to show
        if (updatedParams.slideItems) {
            var $newSliderElement = $('<div></div>').attr({
                'class': 'slider',
                'id': updatedParams.container.id
            });
            var $newSliderControls = $updatedSliderContainer.find('.slider-thumbnail-controls').addClass('d-none');
            $newSliderElement.html(updatedParams.slideItems);
            $updatedSliderContainer.html($newSliderElement);
            updatedParams.container = $newSliderElement[0];

            // If this is a thumbnails slider, also add the arrow controls and update the item count
            if (isThumbnail) {
                $updatedSliderContainer.append($newSliderControls);
                updatedParams.items = updatedParams.slideItems.length;
                updatedParams.responsive[mediumBreakpoint].items = updatedParams.slideItems.length;
                updatedParams.responsive[largeBreakpoint].items = updatedParams.slideItems.length;
            }
        }

        // Reinitialize slider using new params
        slider = tinySlider.tns(updatedParams);
        slider.events.on('transitionEnd', abSlider.slideIntoView);
        abSlider.enableImageZoom($updatedSliderContainer);

        if (isThumbnail) {
            abSlider.handleThumbnailArrows($updatedSliderContainer, slider);
        } else {
            // listen for videos getting updated from getUpdatedSlideItems function
            $('body').on('slider:videosLoaded', () => {
                abSlider.handleSliderVideos($updatedSliderContainer, slider);
            });
        }
    });
}

// Listen for the slider:destroy event
abSlider.bindSliderDestroyEvent = function($sliderContainer, slider) {
    $sliderContainer.off('slider:destroy').on('slider:destroy', '.slider', () => {
        slider.destroy();
    });
}

// Enables zoomed images using jquery-zoom
abSlider.enableImageZoom = function($sliderContainer) {
    if ($sliderContainer.closest('.primary-images').length
        && !$sliderContainer.hasClass('slider-thumbnails')
        && !window.isMobile()) {
        var $slides = $sliderContainer.find('.slide');
        $slides.each((index, slide) => {
            var $slide = $(slide);

            if (!$slide.hasClass('no-zoom')) {
                var zoomImageUrl = $slide.data('zoom-image') || $slide.find('img').attr('src');
                $slide.zoom({
                    url: zoomImageUrl,
                    magnify: 1,
                    touch: false
                });
            }
        });
    }
}

// Determine whether to show thumbnail arrows based on overflow state
abSlider.handleThumbnailArrows = function($sliderContainer, slider) {
    if ($sliderContainer.hasClass('slider-thumbnails')) {
        var $slider = $sliderContainer.find('.slider');
        var $arrows = $sliderContainer.find('.slider-thumbnail-controls');
        var buffer = 5; // adding a buffer to prevent arrows from showing if the last slide is mostly visible
        var containerSize;
        var sliderSize;

        $slider.imagesLoaded().done(() => {
            if (window.isMobile()) {
                containerSize = $sliderContainer.outerWidth();
                sliderSize = $slider[0].scrollWidth;
            } else {
                containerSize = $sliderContainer.outerHeight();
                sliderSize = $slider[0].scrollHeight;
            }

            if (sliderSize - buffer >= containerSize) {
                $arrows.removeClass('d-none');
            }
        });
    }
}

// Check for videos and pause them when they're not in the active slide
abSlider.handleSliderVideos = function($sliderContainer, slider) {
    var $videos = $sliderContainer.find('.hero-media.video-container, .experience-commerce_assets-video>.video-container');

    if ($videos.length) {
        slider.events.on('indexChanged', () => {
            $videos.each((_i, element) => {
                var $videoContainer = $(element);
                var videoID = $videoContainer.find('.video-player').attr('id');
                var player = window.Players[videoID];

                if (player) {
                    if (!$videoContainer.closest('.tns-slide-active').length) {
                        player.pause();
                    } else if (player.config.autoplay) {
                        player.play();
                    }
                }
            });
        });
    }
}

// Handle Pause/Play Button
abSlider.bindPauseButton = function($sliderContainer, slider) {
    var sliderButtonPlacement = $sliderContainer.attr('data-slider-pause-button-placement');

    if ($sliderContainer[0].hasAttribute('data-slider-autoplay') && $sliderContainer.attr('data-slider-autoplay') != 'false' && $sliderContainer[0].hasAttribute('data-slider-pause-button-placement') && $sliderContainer.attr('data-slider-pause-button-placement') != "none")  {
        if (sliderButtonPlacement == 'with-pagination') {
            $sliderContainer.find('.tns-nav').addClass('with-pagination-btn').append('<a href="#" class="tns-nav-controller"><span class="sr-only">stop and start slider</span></a>');
        } else {
            $sliderContainer.prepend('<a href="#" class="tns-nav-controller"><span class="sr-only">stop and start slider</span></a>');
        };

        $('.tns-nav-controller').addClass(sliderButtonPlacement);

        $sliderContainer.on('click', '.tns-nav-controller', function() {
            event.preventDefault();
            $(this).toggleClass('pause');
            $sliderContainer.toggleClass('slider-pause');

            if ($sliderContainer.hasClass('slider-pause')) {
                slider.pause();
            } else {
                slider.play();
            }
        });
    }
};

abSlider.initializeSliders = function($context = $('body')) {
    var scope = this;
    // delay-init is different from replace-content
    var $sliderContainers = $context.find('.slider-container:not(.delay-init)');

    $sliderContainers.each((i, slider) => {
        var $sliderContainer = $(slider);
        var params = scope.getSliderParams($sliderContainer);
        var promisegroup = [];
        var $replace = $sliderContainer.find('[data-replace-content]');

        $replace.each(function (i, replace) {
            var $target = $(replace);
            var deferment = $target.data('deferment');

            if (deferment && !deferment.isResolved && deferment.state() === 'pending') {
                promisegroup.push(deferment);
            }
        });

        // Done will also execute if promise group is empty []
        $.when.apply($, promisegroup).done(function () {
           $(params.container).find('.slide:empty').remove();

            scope.applyTinySlider($sliderContainer, params);
        });
    });
}

abSlider.applyTinySlider = function ($container, params) {
    if (!$container) return;

    var tslider = tinySlider.tns(params);

    if (!!tslider) {
        tslider.events.on('transitionStart', this.slideIntoView);

        this.bindPauseButton($container, tslider);
        this.bindSliderUpdateEvent($container, tslider);
        this.bindSliderDestroyEvent($container, tslider);
        this.enableImageZoom($container);
        this.handleThumbnailArrows($container, tslider);
        this.handleSliderVideos($container, tslider);

        $('body').trigger('slider:init.sliderEvents', [$container]);
    }

    $container.data('tns', tslider);
    $container.trigger('tooltip:init');
}

abSlider.initializeQuickviewModalSliders = function() {
    $('body').off('quickview:ready').on('quickview:ready', (event, modal) => {
        abSlider.initializeSliders($(modal));
    });
}

abSlider.initializeWishlistModalSliders = function() {
    $('body').off('editwishlistproduct:ready').on('editwishlistproduct:ready', (event, modal) => {
        abSlider.initializeSliders($(modal));
    });
}

abSlider.initializeTargetSliders = function() {
    $('body').off('targetslider:ready').on('targetslider:ready', (event, target) => {
        abSlider.initializeSliders($(target));
    });
}

abSlider.init =  function() {
    abSlider.initializeSliders();
    abSlider.initializeQuickviewModalSliders();
    abSlider.initializeWishlistModalSliders();
    abSlider.initializeTargetSliders();
}

module.exports = abSlider;
