const moment = require('moment');
const Cookies = require('js-cookie');

function setVoteCount($el, diff) {
    const voteCount = parseInt($el.text());
    let newCount = voteCount + diff;

    if (newCount < 0) newCount = 0;

    $el.text(newCount);
}

module.exports = {
    methods: {
        initYotpoPDP: async function () {
            const $container = $('.yotpo-reviews-container');

            if (!$container.length) {
                $('.product-number-rating').detach();

                return;
            }

            const url = $container.data('url');
            const response = await $.ajax({ url, dataType: 'html' });

            let $response = $(response);

            // because sfcc dev boxes inject comments
            if ($response.length > 1) {
                $response = $($response.get(0));
            }

            const $reviews = $response.find('.yotpo-review');

            // remove the reviews container if we have none to show
            if ($reviews.length === 0) {
                $container.detach();

                return;
            }

            // set the human-readable timestamp when the review was posted
            $reviews.each(function () {
                const $when = $(this).find('.when');

                $when.text(moment($when.text()).fromNow()).addClass('dateset');
            });

            // insert the reviews onto the page
            $container.empty();
            $container.append($response);

            // make sure the stars at the top will scroll to reviews when clicked
            $('body').on('click', '.star-clickable', function () {
                $('html, body').animate({
                    scrollTop: $container.offset().top
                }, 1000);
            });

            // register vote behavior on each review
            $response.find('.yotpo-review').each(function () {
                const $review = $(this);
                const $voteBtns = $review.find('.js-vote');
                const reviewId = $review.data('reviewId');

                $voteBtns.click(function (e) {
                    e.preventDefault();

                    // cookie tracks which direction they have voted (if at all)
                    const voted = Cookies.get(`voted-${reviewId}`);
                    const $btn = $(this);

                    // the current vote action direction
                    const voteDirection = $btn.data('voteDirection');
                    const url = `https://api.yotpo.com/reviews/${reviewId}/vote/${voteDirection}`;

                    let undo = Promise.resolve();

                    // remove previous vote
                    if (voted) {
                        const $btn = $voteBtns.filter(`a[data-vote-direction="${voted}"]`);

                        undo = $.ajax({
                            url: `${url}/true`,
                            method: 'post'
                        });

                        setVoteCount($btn.find('.js-vote-count'), -1);
                    }

                    // clear cookie if no current votes cast
                    if (voted === voteDirection) {
                        Cookies.remove(`voted-${reviewId}`);
                    } else {
                        // increment the vote count
                        setVoteCount($btn.find('.js-vote-count'), 1);

                        // track the vote direction with a cookie
                        Cookies.set(`voted-${reviewId}`, voteDirection);

                        // cast the vote
                        undo.then(() => {
                            $.ajax({ url, method: 'post' });
                        });
                    }
                })
            });

            // event handler for load more button
            $('body').on('click', '.yotpo-reviews .loadmore .btn', function () {
                $.spinner().start();
                var loadMoreURL = $(this).data('url');
                $.ajax({
                    url: loadMoreURL,
                    dataType: 'json',
                    success: function (data) {
                        $('.loadmore').replaceWith(data.renderedTemplate);
                        $('.yotpo-review').each(function () {
                            const $when = $(this).find('.when:not(.dateset)');

                            $when.text(moment($when.text()).fromNow()).addClass('dateset');
                        });
                    }
                }).done(function () {
                    $.spinner().stop();
                });
            });
        }
    }
}
