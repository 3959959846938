'use strict';

const base = require('core/components/slider');

const ogGetUpdatedSlideItems = base.getUpdatedSlideItems;

function getUpdatedSlideItems (images, assets, isThumbnail, zoom = true) {
    const slideItems = ogGetUpdatedSlideItems.call(this, images, assets, isThumbnail);

    if (!zoom) {
        slideItems.forEach(function (slide) {
            $(slide).addClass('no-zoom');
        });
    }

    return slideItems;
}

base.getUpdatedSlideItems = getUpdatedSlideItems;

module.exports = base;
