'use strict';

var baseCore = require('integrations/product/base');
baseCore.sizeChart= function() {
    $('body').on('click', '.size-chart .size-chart-launcher', event => {
        event.preventDefault();
        var url = $(event.target).attr('href');
        var productId = $(event.target).closest('.product-detail').attr('data-pid');
        var $sizeChartModal = $('.modal[data-product=' + productId + ']');
        var $modalBody = $sizeChartModal.find('.modal-body');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            success: function (data) {
                $modalBody.html(data);
            }
        });

        //if the sizechart is from a quickview append after all the modal-backdrops
        if ($(event.target).parents('.product-quickview').length) {
            var $sizeChartContainer = $(event.target).closest('.size-chart');

            $sizeChartModal.appendTo('body');
            $sizeChartModal.on('hide.bs.modal', event => {
                $sizeChartModal.appendTo($sizeChartContainer);
            });
        }
        $sizeChartModal.modal('show');
    });

    $('body').on('click', '#sizeChartModal .close', event =>  {
        $(event.target).closest('#sizeChartModal').modal('hide');
    });
}

// Modified to add classes for availability messages
baseCore.methods.updateAvailabilityProcess = function(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div class="' + response.resources.info_selectforstock.toLowerCase().replace(/ /g, '-') + '">' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><div class="' + message.toLowerCase().replace(/ /g, '-') + '">' + message + '</div></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}


module.exports = baseCore;