const base = require('core/components/menu');
const SiteConstants = require('constants/SiteConstants');

const ogDropdownMenu = base.dropdownMenu;
const ogClearSelection = base.clearSelection;
const ogToggleDrawer = base.toggleDrawer;

function toggleDrawer (status) {
    ogToggleDrawer.call(this, status);

    if (status !== 'open') {
        $('header .main-menu .navbar-nav .primary-category').detach();
    }
}

function dropdownMenu() {
    ogDropdownMenu.apply(this, arguments);

    // Custom dropdown behaviors for top menu
    const $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');

    $dropdownMenu.on('click', event => {
        event.stopPropagation();

        if (window.isMobile()) {
            // Drawer behavior on mobile
            event.preventDefault();

            const $dropdownLink = $(event.target);
            const $dropdown = $dropdownLink.closest('.custom-dropdown');
            const $primaryCategory = $dropdown.closest('.dropdown-menu').find('.dropdown-item.primary-category');

            if ($primaryCategory.length === 1) {
                $primaryCategory.clone().insertAfter($dropdown.children('.dropdown-menu').find('.nav-menu'));
            } else if ($dropdown.hasClass('megamenu')) {
                const $li = $('<li class="dropdown-item primary-category d-lg-none" role="button"></li>');
                const link = $dropdownLink.clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');

                $li.append(link);

                const $target = $dropdown.children('.dropdown-menu');

                if ($target.find('.megamenu-asset-only:last-child').length === 0) {
                    $li.insertAfter($target.find('.nav-menu'));
                }
            }
        }
    });
}

function clearSelection(element) {
    const $currentPane = $(element).closest('.custom-dropdown.show');

    setTimeout(() => {
        $currentPane.find('.primary-category').detach();
    }, SiteConstants.TransitionSpeed);

    ogClearSelection.call(this, element);
}

base.dropdownMenu = dropdownMenu;
base.clearSelection = clearSelection;
base.toggleDrawer = toggleDrawer;

module.exports = base;
