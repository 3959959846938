'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var SiteConstants = require('constants/SiteConstants');
var hoverIntent = require('jquery-hoverintent');
var headerUtils = require('../utilities/headerUtils');

function clearSelection(element) {
    var $currentPane = $(element).closest('.custom-dropdown.show');

    $currentPane.removeClass('show');

    // Adjust DOM after drawer finishes closing (mobile) or dropdown finishes hiding (desktop)
    setTimeout(() => {
        $currentPane.find('.nav-link').attr('aria-expanded', 'false');
        $currentPane.find('.dropdown-menu').attr('aria-hidden', 'true');
        $currentPane.find('.top-category').detach();
        $currentPane.find('.nav-menu').detach();

        if (!$currentPane.closest('.custom-dropdown.show').length) {
            $currentPane.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
        }
    }, SiteConstants.TransitionSpeed);
};

function toggleDrawer(status) {
    var $header = $('header');
    var $headerNav = $header.find('.header-nav');
    var headerNavHeight = headerUtils.getHeaderHeight();
    var $mainMenu = $header.find('.main-menu');
    var $navbar = $mainMenu.find('.navbar-nav');
    var $modalBackground = $('.modal-background');

    if (status === 'open') {
        $('html').scrollTop($headerNav.offset().top);
        $('html').addClass('lock-scroll');
        $('body').addClass('mobile-menu-in');
        $mainMenu
            .addClass('in')
            .attr('aria-hidden', 'false')
            .css('top', headerNavHeight)
            .siblings().attr('aria-hidden', 'true');
        $modalBackground
            .fadeIn(SiteConstants.TransitionSpeed)
            .css('top', headerNavHeight);
        $header
            .siblings().attr('aria-hidden', 'true');
        $navbar
            .find('.nav-link').first().focus();

    } else {
        $('body').removeClass('mobile-menu-in');
        $navbar
            .find('.nav-menu, .top-category').detach();
        $navbar
            .find('.thirdCatOpen').removeClass('thirdCatOpen');
        $navbar
            .find('.show').removeClass('show');
        $mainMenu
            .removeClass('in')
            .attr('aria-hidden', 'true')
            .siblings().attr('aria-hidden', 'false');
        $header
            .siblings().attr('aria-hidden', 'false');
        $modalBackground
            .fadeOut(SiteConstants.TransitionSpeed);

        setTimeout(() => {
            $modalBackground.css('top', '');
            $('html').removeClass('lock-scroll');
            $headerNav.addClass('transparent-nav');
        }, SiteConstants.TransitionSpeed);
    }
};

function headerBanner() {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', () => {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }
};

function kbAccessibility() {
    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link, .header-account-container > div, .header-support-container > div, .minicart > div',
        {
            40: function(menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                    $(this).attr('aria-expanded', 'true');
                } else if (menuItem.is('.header-account-container, .header-support-container')) { // account/support
                    menuItem.find('.custom-dropdown').addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'true');
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a').first().focus(); // set focus to the first menuitem
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function(menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.is('.header-account-container, .header-support-container')) { // account/support
                    menuItem.find('.custom-dropdown').addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'true');
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a').first().focus();
                }
            },
            38: function(menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                } else if (menuItem.is('.minicart')) { // minicart
                    menuItem.find('.popover').removeClass('show');
                } else if (menuItem.is('.header-account-container, .header-support-container')) { // account/support
                    menuItem.find('.custom-dropdown').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children().first().focus(); // set the focus to the last menuItem
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function(menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else if (menuItem.is('.minicart')) { // minicart
                    menuItem.find('.popover').removeClass('show');
                } else if (menuItem.is('.header-account-container, .header-support-container')) { // account/support
                    menuItem.find('.custom-dropdown').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children().first().focus().attr('aria-expanded', 'false');
                }
            },
            27: function(menuItem) { // escape
                if (menuItem.is('.minicart')) { // minicart
                    menuItem.find('.popover').removeClass('show');
                } else if (menuItem.is('.header-account-container, .header-support-container')) { // account/support
                    menuItem.find('.custom-dropdown').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
                } else {
                    var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('.show');
                    parentMenu.children('.show').removeClass('show');
                    parentMenu.removeClass('show').children('.nav-link')
                        .attr('aria-expanded', 'false');
                    parentMenu.children().first().focus();
                }
            }
        },
        function () {
            return $(this).parent();
        }
    );
}

function searchExpand() {
    //if only search icon is visible, show the input on focus
    $( ".search-field" ).focus(function() {
        $(this).closest('.site-search').addClass('expanded');
    });
};

function dropdownMenu() {
    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    $dropdownMenu.on('click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events
        if (window.isMobile()) {
            // Drawer behavior on mobile
            event.preventDefault();
            var $dropdownLink = $(event.target);
            var $dropdown = $dropdownLink.closest('.custom-dropdown');
            var $li = $('<li class="dropdown-item top-category" role="button"></li>');
            var $closeMenu = $('<li class="nav-menu"></li>');
            var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                .removeAttr('data-toggle')
                .removeAttr('aria-expanded')
                .attr('aria-haspopup', 'false');
            $li.append(link);
            $closeMenu.append($('.close-menu').first().clone());
            $dropdown.children('.dropdown-menu')
                .prepend($li)
                .prepend($closeMenu)
                .attr('aria-hidden', 'false')
                .find('a').removeAttr('tabindex', '-1');
            $dropdown.addClass('show');
            $dropdownLink.attr('aria-expanded', 'true');
            $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
            $(link).focus();
            $('.dropdown-menu').scrollTop(0);
        }
    });

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: event => {
            if (!window.isMobile()) {
                var eventElement = event.target;
                // Close all dropdowns
                $('.navbar-nav > li').each((index, element) => {
                    if (!$.contains(element, eventElement)) {
                        var $navItem = $(element);
                        $navItem.find('.show').each(() => {
                            module.exports.clearSelection(element);
                        });
                        if ($navItem.hasClass('show')) {
                            $navItem.removeClass('show');
                            $navItem.children('ul.dropdown-menu').removeClass('show');
                            $navItem.children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // Open current dropdown
                $(eventElement).parent().addClass('show');
                $(eventElement).siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                $(eventElement).attr('aria-expanded', 'true');
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 50
    });
     // Desktop - close menu on mouseleave
     $dropdownMenu.parent().on('mouseleave', event => {
        if (!window.isMobile()) {
            var $dropdown = $(event.currentTarget);
            // Close current dropdown
            $dropdown.removeClass('show');
            $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
            $dropdown.children('.nav-link').attr('aria-expanded', 'false');
        }
    });
};

function megaLeftHover() {
    //Megamenu Mega-left menu
    $('.mega-left-menu-link').hoverIntent( function() {
            var menuName = this.id,
                menuRight = $('#' + menuName + '-right'),
                menuLeft = $(this).parent().parent(),
                menuRightParent = menuRight.closest('.mega-left');

            if (!window.isMobile()) {
                // clear classes
                menuLeft.find('.active').removeClass('active');
                menuRightParent.removeClass('has-sub-asset');
                menuRightParent.find('.active-list').removeClass('active-list');
                // add classes
                $(this).addClass('active');
                menuRight.parent().addClass('active-list');
                // add a class to the parent Ul if a third level asset is displayed; css-hides the top level asset
                if (menuRight.parent().next().hasClass('sub-asset-1') || menuRight.parent().next().hasClass('sub-asset-2')) {
                    menuRightParent.addClass('has-sub-asset');
                }
            }
        },
        50
    );
};

function navbarBackBtn() {
    $('.navbar-nav').on('click', '.back', event => {
        event.preventDefault();
        module.exports.clearSelection(event.target);
    });
};

function navbarToggler() {
    $('.navbar-toggler').click(event => {
        event.preventDefault();
        if ($('body').hasClass('mobile-menu-in')) {
            module.exports.toggleDrawer('close');
            $('.header-nav').addClass('transparent-nav');
        } else {
            module.exports.toggleDrawer('open');
            $('.header-nav').removeClass('transparent-nav');
        }
    });

    $('html').on('click', '.mobile-menu-in .modal-background', () => module.exports.toggleDrawer('close'));
};

function mobileSearchFix() {
    // Prevent iOS from scrolling to bad position on search field input focus
    $('.search-mobile .search-field').focus(event => {
        if (window.isMobile()) {
            var currentScrollPosition = $('html').scrollTop();
            setTimeout(() => {
                $('html').scrollTop(currentScrollPosition);
                $('.modal-background').show();
            }, SiteConstants.TransitionSpeed);
        }
    });
};

module.exports = {
    clearSelection: clearSelection,
    toggleDrawer: toggleDrawer,
    headerBanner: headerBanner,
    kbAccessibility: kbAccessibility,
    searchExpand: searchExpand,
    dropdownMenu: dropdownMenu,
    megaLeftHover: megaLeftHover,
    navbarBackBtn: navbarBackBtn,
    navbarToggler: navbarToggler,
    mobileSearchFix: mobileSearchFix
}
