'use strict';

const base = require('core/components/consentTracking');

base.getString = (urlAccept, textAccept) => {
    return `<div class="consent-drawer" id="consent-tracking" role="dialog">
            <div class="consent-text-wrapper"></div>
            <div class="consent-button-wrapper ml-auto">
                <button class="affirm btn btn-primary" data-url="${urlAccept}" autofocus>
                ${textAccept}
                </button>
            </div>
        </div>`;
}

module.exports = base;
